// src/data/questions.js

const questions = [
    { text: 'People often ask me for spiritual or personal advice.', category: 'Wisdom' },
    { text: 'I can effectively explain God’s Word to others.', category: 'Teaching' },
    { text: 'I receive pleasure when caring for others.', category: 'Shepherding' },
    { text: 'I can speak God’s Word so that others turn from sin.', category: 'Prophecy' },
    { text: 'I can feel the pain of suffering people and try to help them in their recovery.', category: 'Mercy' },
    { text: 'I can inspire others to achieve their goals.', category: 'Leadership' },
    { text: 'People often ask me about the meaning of a particular Scripture or biblical truth.', category: 'Knowledge' },
    { text: 'I often ask people for their prayer requests so I can pray for them.', category: 'Intercession' },
    { text: 'I enjoy using my home as a place to minister to others.', category: 'Hospitality' },
    { text: 'I prefer to work “behind the scenes” on a project or program.', category: 'Helps' },
    { text: 'I generously give to people in need or to programs, and I receive joy in doing so.', category: 'Giving' },
    { text: 'It’s easy for me to trust God to answer my prayers.', category: 'Faith' },
    { text: 'I can explain the Gospel message to others so that it’s understood.', category: 'Evangelism' },
    { text: 'I usually look for the best in people.', category: 'Encouragement' },
    { text: 'I can tell the difference between spiritual truth and false teachings.', category: 'Discernment' },
    { text: 'I enjoy the details of planning events and tasks and organizing groups.', category: 'Administration' },
    { text: 'I’m a good problem solver and can find solutions often overlooked by others.', category: 'Wisdom' },
    { text: 'I enjoy personal Bible study and research, knowing that the information I present can affect someone’s eternity.', category: 'Teaching' },
    { text: 'I desire to help wandering believers get back on the right path.', category: 'Shepherding' },
    { text: 'I’m compelled to confront people with the truth of God’s Word.', category: 'Prophecy' },
    { text: 'I have patience when it comes to helping people get their life back on track after a crisis.', category: 'Mercy' },
    { text: 'Helping communicate a vision is one of my strengths.', category: 'Leadership' },
    { text: 'It’s easy for me to share my Bible knowledge with others.', category: 'Knowledge' },
    { text: 'I often lose track of the time when I pray and believe God gives me special insight in knowing what to pray about.', category: 'Intercession' },
    { text: 'I like to meet new people and help them feel comfortable in new surroundings.', category: 'Hospitality' },
    { text: 'I don’t mind doing repetitive and routine work if it supports the ministry of my church.', category: 'Helps' },
    { text: 'God has given me extra financial resources so that I might be able to generously support the church.', category: 'Giving' },
    { text: 'Even during difficult circumstances, I have confidence that God will provide assistance and resources to get me through.', category: 'Faith' },
    { text: 'I like to develop relationships with non-Christians in order to share the Gospel with them.', category: 'Evangelism' },
    { text: 'I am able to encourage and reassure others.', category: 'Encouragement' },
    { text: 'My first impression of people is usually accurate.', category: 'Discernment' },
    { text: 'I organize work in a thorough and detailed manner.', category: 'Administration' },
    { text: 'I can select the best solution that is needed to resolve an issue.', category: 'Wisdom' },
    { text: 'I carefully note, review, and evaluate the material that is taught by others.', category: 'Teaching' },
    { text: 'I like to give holistic guidance to help people with any need or problem they may have.', category: 'Shepherding' },
    { text: 'I’m willing to go against the current cultural trend and philosophy if it is not supportive of biblical principles.', category: 'Prophecy' },
    { text: 'I enjoy helping people who are often seen by others as dysfunctional, “high maintenance,” or hopeless.', category: 'Mercy' },
    { text: 'I can relate well to others in order to help them reach their full potential.', category: 'Leadership' },
    { text: 'I often schedule time when I can read and study Scripture so that I gain biblical truth and insight.', category: 'Knowledge' },
    { text: 'I feel as though I am ministering to others when I pray for them.', category: 'Intercession' },
    { text: 'I believe God directs people to me who are in need of friendship and community.', category: 'Hospitality' },
    { text: 'I enjoy taking on those little jobs at church that no one else seems to want to do.', category: 'Helps' },
    { text: 'I am able to give more of my money to church because I can save and manage my resources well.', category: 'Giving' },
    { text: 'I know that God will help me accomplish anything he sets before me.', category: 'Faith' },
    { text: 'I can help people connect to the Gospel so it applies to their needs.', category: 'Evangelism' },
    { text: 'I enjoy sharing the promises of God so that others may receive hope.', category: 'Encouragement' },
    { text: 'I can spot a “phony” when others may be deceived by their actions.', category: 'Discernment' },
    { text: 'I can establish goals and objectives in order to achieve results.', category: 'Administration' },
    { text: 'I can usually determine the outcome of a person’s lifestyle choices.', category: 'Wisdom' },
    { text: 'I enjoy a systematic approach to Bible study.', category: 'Teaching' },
    { text: 'I am able to provide long-term care to those who need my support.', category: 'Shepherding' },
    { text: 'I feel the need to correct people when their actions are wrong or contrary to God’s Word.', category: 'Prophecy' },
    { text: 'My heart goes out to hurting people.', category: 'Mercy' },
    { text: 'I enjoy goal-setting and leading people to accomplish their goals.', category: 'Leadership' },
    { text: 'I usually discover biblical insights when studying Scripture, which helps others better understand God’s Word.', category: 'Knowledge' },
    { text: 'When I hear about a crisis or someone in need, I feel an urgency to pray.', category: 'Intercession' },
    { text: 'I can create an accepting environment in any setting where people feel at home.', category: 'Hospitality' },
    { text: 'I like to use my learned skills to help out with whatever job needs to be done.', category: 'Helps' },
    { text: 'I often limit my lifestyle options so I can give my church a higher portion of my income.', category: 'Giving' },
    { text: 'I trust God in circumstances where success cannot be guaranteed by human effort alone.', category: 'Faith' },
    { text: 'I enjoy asking people to make a faith commitment to Jesus.', category: 'Evangelism' },
    { text: 'I take pleasure in encouraging people who may be doubtful in their faith.', category: 'Encouragement' },
    { text: 'I usually see things as black or white, right or wrong.', category: 'Discernment' },
    { text: 'I am able to identify and utilize the resources necessary to complete a task.', category: 'Administration' }
  ];
  
  export default questions;